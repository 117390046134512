import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AllSymbolsPage from './pages/AllSymbolsSearch';
import Layout from './Layout';
import Dashboard from './Dashboard';
import Analytics from './Analytics';
import FundingRates from './FundingRates';
import MarketMaking from './MarketMaking';
import RealTimeChart from './pages/RealTimeChart'; // Import the new page
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout toggleDarkMode={toggleDarkMode} isMobile={isMobile}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/all-symbols" element={<AllSymbolsPage />} />
            <Route path="/funding-rates" element={<FundingRates />} />
            <Route path="/market-making" element={<MarketMaking />} />
            <Route path="/real-time-chart" element={<RealTimeChart />} /> {/* New route */}
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
