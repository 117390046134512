import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function FundingRates() {
  const [fundingData, setFundingData] = useState([]);

  useEffect(() => {
    const fetchFundingRates = async () => {
      const response = await fetch('https://api.quantumvoid.org/volumedata/funding_bybit.json');
      const data = await response.json();
      setFundingData(data);
    };

    fetchFundingRates();
    const intervalId = setInterval(fetchFundingRates, 60000); // Fetch data every 60 seconds

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const chartData = {
    labels: fundingData.map(data => data.Asset),
    datasets: [
      {
        label: 'Funding Rates',
        data: fundingData.map(data => data.Funding),
        backgroundColor: data => data.Funding >= 0 ? 'rgba(53, 162, 235, 0.5)' : 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false, // Add this to control chart size and prevent cutting off
  };

  return (
    <div style={{ height: '500px' }}> {/* Adjust the height as needed */}
      <h2>Funding Rates</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
}

export default FundingRates;