import React, { useState } from 'react';
import { Box, Drawer, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon, ListItemText, Divider, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FundingRatesIcon from '@mui/icons-material/TrendingUp';
import LoginIcon from '@mui/icons-material/Login';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import MarketMakingIcon from '@mui/icons-material/BusinessCenter';
import LineStyleIcon from '@mui/icons-material/LineStyle'; // Import an icon for Real-Time Chart

const drawerWidth = 240;

function Layout({ children, toggleDarkMode }) {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }} // Removed the display property to make it visible on all screen sizes
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Quantum Void Labs
          </Typography>
          <IconButton color="inherit" onClick={toggleDarkMode}>
            <Brightness4Icon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={open}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, // Adjusted for all screen sizes
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem button component={Link} to="/" onClick={handleDrawerClose}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/analytics" onClick={handleDrawerClose}>
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText primary="Analytics" />
            </ListItem>
            <ListItem button component={Link} to="/funding-rates" onClick={handleDrawerClose}>
              <ListItemIcon>
                <FundingRatesIcon />
              </ListItemIcon>
              <ListItemText primary="Funding Rates" />
            </ListItem>
            <ListItem button component={Link} to="/all-symbols" onClick={handleDrawerClose}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="All Symbols" />
            </ListItem>
            <ListItem button component={Link} to="/market-making" onClick={handleDrawerClose}>
              <ListItemIcon>
                <MarketMakingIcon />
              </ListItemIcon>
              <ListItemText primary="Market Making" />
            </ListItem>
            <ListItem button component={Link} to="/real-time-chart" onClick={handleDrawerClose}> {/* New tab */}
              <ListItemIcon>
                <LineStyleIcon />
              </ListItemIcon>
              <ListItemText primary="Liquidation Map" />
            </ListItem>
            <ListItem button component={Link} to="/login" onClick={handleDrawerClose}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default Layout;
