import React, { useEffect, useState, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import { CandlestickController, CandlestickElement } from 'chartjs-chart-financial';
import axios from 'axios';
import 'chartjs-adapter-date-fns';

Chart.register(CandlestickController, CandlestickElement);

function RealTimeChart() {
  const [timeframe, setTimeframe] = useState('1m');
  const candlestickChartRef = useRef(null);
  const liquidationChartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/liquidation_heatmap_data_${timeframe}.json?t=${new Date().getTime()}`);
        
        if (!response || !response.data || !Array.isArray(response.data)) {
          throw new Error("Fetched data is not an array or is undefined");
        }

        const data = response.data;

        createCandlestickChart(data);
        createLiquidationChart(data);
      } catch (error) {
        console.error("Error fetching heatmap data", error);
      }
    };

    fetchData();
  }, [timeframe]);

  const createCandlestickChart = (data) => {
    if (candlestickChartRef.current) {
      candlestickChartRef.current.destroy();
    }

    const ctx = document.getElementById('candlestickChart').getContext('2d');
    candlestickChartRef.current = new Chart(ctx, {
      type: 'candlestick',
      data: {
        datasets: [{
          label: 'Price',
          data: data.map(item => ({
            x: new Date(item.timestamp * 1000),
            o: item.open,
            h: item.high,
            l: item.low,
            c: item.close
          }))
        }]
      },
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute',
              tooltipFormat: 'MMM dd, yyyy HH:mm',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Price'
            }
          }
        }
      }
    });
  };

  const createLiquidationChart = (data) => {
    if (liquidationChartRef.current) {
      liquidationChartRef.current.destroy();
    }

    const ctx = document.getElementById('liquidationChart').getContext('2d');
    liquidationChartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Price',
            data: data.map(item => ({ x: new Date(item.timestamp * 1000), y: item.close })),
            borderColor: 'white',
            backgroundColor: 'white',
            fill: false,
            yAxisID: 'y'
          },
          {
            label: 'Long Liquidation',
            data: data.map(item => ({ x: new Date(item.timestamp * 1000), y: item.liquidation_price_long })),
            borderColor: 'rgba(0, 255, 0, 0.8)',
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            fill: true,
            yAxisID: 'y'
          },
          {
            label: 'Short Liquidation',
            data: data.map(item => ({ x: new Date(item.timestamp * 1000), y: item.liquidation_price_short })),
            borderColor: 'rgba(255, 0, 0, 0.8)',
            backgroundColor: 'rgba(255, 0, 0, 0.2)',
            fill: true,
            yAxisID: 'y'
          }
        ]
      },
      options: {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute',
              tooltipFormat: 'MMM dd, yyyy HH:mm',
            },
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: 'Price'
            }
          }
        }
      }
    });
  };

  return (
    <div style={{ backgroundColor: '#1b1b1b', padding: '20px', borderRadius: '8px' }}>
      <h1 style={{ color: '#ffffff' }}>Real-Time Liquidation Prices</h1>
      <div>
        <label style={{ color: '#ffffff' }}>Select Timeframe: </label>
        <select
          value={timeframe}
          onChange={e => setTimeframe(e.target.value)}
          style={{ marginLeft: '10px', padding: '5px', borderRadius: '4px' }}
        >
          <option value="1m">1 Minute</option>
          <option value="5m">5 Minutes</option>
          <option value="15m">15 Minutes</option>
          <option value="1h">1 Hour</option>
        </select>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '20px' }}>
        <div style={{ height: '400px' }}>
          <canvas id="candlestickChart"></canvas>
        </div>
        <div style={{ height: '400px' }}>
          <canvas id="liquidationChart"></canvas>
        </div>
      </div>
    </div>
  );
}

export default RealTimeChart;