import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement, // Import PointElement
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement, // Make sure to register the PointElement
  Title,
  Tooltip,
  Legend
);

function HistoricalVolumeChart() {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        const fetchVolumeData = async () => {
            const response = await fetch('https://api.quantumvoid.org/volumedata/total_historical_volume_bybit.json');
            const data = await response.json();
            // Assuming each key has the same length of volume data, we use the first key to determine the labels
            // This part assumes that the volume data is consistently structured across all assets
            const sampleAsset = data[Object.keys(data)[0]];
            const labels = sampleAsset[1].map((_, index) => `Period ${index + 1}`);

            const datasets = Object.entries(data).map(([asset, [_, volumeData]]) => ({
                label: asset,
                data: volumeData,
                borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.5)`,
                backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
            }));

            setChartData({
                labels,
                datasets
            });
        };

        fetchVolumeData();
    }, []);

    const options = {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value, index, values) {
                return value.toLocaleString(); // Format the value with comma separators
              },
            },
          },
        },
      };

    // const options = {
    //     responsive: true,
    //     scales: {
    //         y: {
    //             beginAtZero: true
    //         }
    //     }
    // };

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <Line data={chartData} options={options} />
        </div>
    );
}

export default HistoricalVolumeChart;
