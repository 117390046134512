import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AllSymbolsPage = () => {
  const [volumeData, setVolumeData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios('https://api.quantumvoid.org/data/total_historical_volume_bybit.json');
      setVolumeData(result.data);
    };

    fetchData();
  }, []);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Historical Volume',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
  };

  const formatChartData = (data) => ({
    labels: Array.from({ length: data.length }, (_, i) => i + 1),
    datasets: [
      {
        label: 'Volume',
        data: data,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.1,
        pointRadius: 0,
      },
    ],
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSymbols = Object.entries(volumeData).filter(([symbol]) =>
    symbol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto' }}>
      <h1>All Symbols</h1>
      <input
        type="text"
        placeholder="Search symbols..."
        value={searchTerm}
        onChange={handleSearch}
      />
      {filteredSymbols.map(([symbol, [_, data]]) => (
        <div key={symbol} style={{ marginBottom: '40px' }}>
          <h2>{symbol}</h2>
          <Line options={chartOptions} data={formatChartData(data)} />
        </div>
      ))}
    </div>
  );
};

export default AllSymbolsPage;