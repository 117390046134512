import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';
import HistoricalVolumeChart from './HistoricalVolumeChart'; // Ensure this path is correct based on your file structure

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function sortedRowInformation(rowArray, comparator) {
  const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedRowArray.map((el) => el[0]);
}

function Analytics() {
    const [data, setData] = useState([]);
    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.quantumvoid.org/volumedata/whattotrade_bybit.json');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 60000);
        return () => clearInterval(interval);
    }, []);

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc');
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sortable table">
                    <TableHead>
                        <TableRow>
                            {/* Dynamically generate TableSortLabel based headers */}
                            {Object.keys(data[0] || {}).map((key) => (
                                <TableCell
                                    key={key}
                                    align={'right'}
                                    sortDirection={valueToOrderBy === key ? orderDirection : false}
                                >
                                    <TableSortLabel
                                        active={valueToOrderBy === key}
                                        direction={valueToOrderBy === key ? orderDirection : 'asc'}
                                        onClick={(event) => handleRequestSort(event, key)}
                                    >
                                        {key.replace(/([A-Z])/g, ' $1').trim()} {/* Formatting key */}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRowInformation(data, getComparator(orderDirection, valueToOrderBy)).map((row, index) => (
                            <TableRow key={index}>
                                {Object.keys(row).map((cell, i) => (
                                    <TableCell key={i} align={'right'}>
                                        {typeof row[cell] === 'boolean' ? (row[cell] ? 'Yes' : 'No') : row[cell]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Adding the HistoricalVolumeChart component below the table */}
            <HistoricalVolumeChart />
        </>
    );
}

export default Analytics;
